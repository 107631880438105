import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import {Button, Icon, STYLES, ICONS, Multivariate, UseCase, Transaction, Xr, Radial} from "../../../components";
import { withRouter } from 'react-router';
import {logger, scrollToRef} from "../../../utilities";
import { create as ConfettiCannon} from 'canvas-confetti';
import { motion } from "framer-motion";
import {heroContent, animateInDown, animateInUp} from '../../../animations';
import chart    from '../../../images/chart.png'
import module1  from '../../../images/aeroponics-iso.png'
import module2  from '../../../images/appliance-iso.png'
import module3  from '../../../images/cold-iso.png'
import module4  from '../../../images/compost-iso.png'
import module5  from '../../../images/fermentation-iso.png'
import module6  from '../../../images/hot-iso.png'
import module7  from '../../../images/pantry-iso.png'
import module8  from '../../../images/trash-iso.png'
import './Narrative.scss';
import Modular from "../../../components/Modular/Modular";
import {Infrastructure } from "./index";


const modules = [module1, module2, module3, module4, module5, module6, module7, module8];
const SLIDER_WIDTH = 200;
const SLIDER_PADDING = 16;
const LOG_PREFIX = 'Narrative.js'

const Narrative = () => { // complex animations here...  stay class

  const [rowCount, setRowCount] = useState(1);
  const [adjustedSliderWidth, setAdjustedSliderWidth] = useState(null);

  const slider = useRef();
  const confettiCanvas = useRef();

  const [confettiContainer, confettiContainerInView] = useInView({
    rootMargin: '0px 0px -25% 0px',
    threshold: 0.5
  })

  const concordOS = useRef(null)
  const concordID = useRef(null)
  const concordAPP = useRef(null)

  // confetti canvas loaded
  useEffect(() => {
    initializeConfetti();
  }, [confettiCanvas]);

  // confetti intersection observer trigger
  useEffect(() => {
    if (confettiContainerInView){
      initializeConfetti();
    }
  }, [confettiContainerInView])

  useLayoutEffect(() => {
    window.scrollTo(0,0);
    const moduleWidths = (SLIDER_WIDTH * modules.length) + (SLIDER_PADDING * modules.length-1);
    let width = window.innerWidth;
    const rowCount = Math.ceil(width / moduleWidths) + 1;

    let adjustedSliderWidth;
    if (window.innerWidth > 550) {
    adjustedSliderWidth = slider.current.offsetWidth * ( Math.ceil(1024 / window.innerWidth) + 4);
    } else {
      adjustedSliderWidth = slider.current.offsetWidth * 15;
    }
    setRowCount(rowCount);
    setAdjustedSliderWidth(adjustedSliderWidth);
  }, [])

  const initializeConfetti = () => {
    let colors = ['#EFBCC4', '#789BCC', '#F5E3B7'];
    logger.debug(LOG_PREFIX, '.initializeConfetti()');
    let confettiBox = ConfettiCannon(confettiCanvas.current, {
      resize: true,
      useWorker: true
    });
    confettiBox({
      particleCount: 100,
      spread: 160,
      colors
    });
  }

  const generateSlider = () => {
    logger.debug(LOG_PREFIX, '.generateSlider()');
    let sliderContent = []
    for (let i = 0; i < rowCount; i++){
      for (let j = 0; j < 8; j++) {
        sliderContent.push(
          <div key={'' + i + '' + j}>
            <img src={modules[j]} alt={`module ${j}`}/>
          </div>
        )
      }
    }
    return sliderContent;
  }

  // const generateMultiValues = () => {
  //   logger.debug(LOG_PREFIX, '.generateMultiValues()');
  //   const data = [
  //     {
  //       asset: <Icon icon={ICONS.ECO} />,
  //       title: 'Ecological',
  //       desc: 'Carbon footprint-Cost resources needed for production Water-to-Produce'
  //     },
  //     {
  //       asset: <Icon icon={ICONS.SOC} />,
  //       title: 'Social',
  //       desc: 'Working- and production conditions impacting the lives of all humans in the supply and production chain'
  //     },
  //     {
  //       asset: <Icon icon={ICONS.HEA} />,
  //       title: 'Health',
  //       desc: 'Nutritional values, Carcinogen Levels, Dietary Restrictions'
  //     },
  //     {
  //       asset: <Icon icon={ICONS.CON} />,
  //       title: 'Convenience',
  //       desc: 'is it better to buy wholesale, or piggy-back from an existing order?'
  //     },
  //     {
  //       asset: <Icon icon={ICONS.FIN} />,
  //       title: 'Financial',
  //       desc: 'Cost-Savings or quality before costs'
  //     },
  //   ]
  //   return(
  //     data.map((o,i) =>
  //       <div key={i} className={'multi-value'}>
  //         <div>
  //           {o.asset}
  //         </div>
  //         <h4>{o.title}</h4>
  //         <p>{o.desc}</p>
  //       </div>
  //     )
  //   );
  // }

  logger.silly(LOG_PREFIX, '.render()', rowCount, adjustedSliderWidth );
  return(
    <motion.div className={'Narrative'} initial='initial' animate='animate' variants={heroContent}>

      <section className={'hero-section'}>
        <div>
          <div className={'hero-banner'}>
            <motion.h2 variants={animateInDown}>
              Welcome to Concord</motion.h2>
            <motion.h3 variants={animateInDown}>
              Your autonomous <br /> modular kitchen system
            </motion.h3>
          </div>
        </div>
        <p>2040</p>
      </section>

      <motion.div
        className={'content col-two'}
        variants={ animateInUp }
      >
        <div className={'shadow-object content card--shortDesc'}>
          <p><em>Concord</em>&nbsp;has been designed for a new paradigm of consumption, built on an infrastructure where machines and artificial intelligent (AI) assistants autonomously exchange goods.</p>
          <br />
          <p>Concord consists of one of these AI assistants which proactively takes care of everyday consumer needs for the benefit of its users and the planet. Within the Concord ecosystem, there are a number of modules through which these needs are met on a day to day basis.</p>
        </div>

        <div className={'shadow-object content circle image'}>
          <img alt={'big-sun modules'} src={require('../../../images/big-sun-pink.jpg')} />
        </div>
      </motion.div>

      <motion.section className={'parts'} variants={animateInDown}>
        <div className={'content'}>
          <h3>Concord is made of three parts:</h3>
          <div className={'parts--content'}>
            <div>
              <motion.div
                className={'circle clickable'}
                onClick={() => scrollToRef(concordOS)}
                whileHover={{
                  scale: 0.85,
                  transition: { duration: .25 },
                }}
              >
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 26 26" fill="none">
                    <path d="M1 7.5C1.27612 7.5 1.5 7.27612 1.5 7V1C1.5 0.723877 1.27612 0.5 1 0.5C0.723877 0.5 0.5 0.723877 0.5 1V7C0.5 7.27612 0.723877 7.5 1 7.5Z" fill="black"/>
                    <path d="M12.2656 0.5C11.9895 0.5 11.7656 0.723877 11.7656 1V7C11.7656 7.27612 11.9895 7.5 12.2656 7.5C12.5417 7.5 12.7656 7.27612 12.7656 7V1C12.7656 0.723877 12.5417 0.5 12.2656 0.5Z" fill="black"/>
                    <path d="M6.63281 7.5C7.71826 7.5 8.60156 6.6167 8.60156 5.53125V2.46875C8.60156 1.3833 7.71826 0.5 6.63281 0.5C5.54736 0.5 4.66406 1.3833 4.66406 2.46875V5.53125C4.66406 6.6167 5.54736 7.5 6.63281 7.5ZM5.66406 2.46875C5.66406 1.93457 6.09863 1.5 6.63281 1.5C7.16699 1.5 7.60156 1.93457 7.60156 2.46875V5.53125C7.60156 6.06543 7.16699 6.5 6.63281 6.5C6.09863 6.5 5.66406 6.06543 5.66406 5.53125V2.46875Z" fill="black"/>
                    <path d="M23.5312 0.5C22.4458 0.5 21.5625 1.3833 21.5625 2.46875V5.53125C21.5625 6.6167 22.4458 7.5 23.5312 7.5C24.6167 7.5 25.5 6.6167 25.5 5.53125V2.46875C25.5 1.3833 24.6167 0.5 23.5312 0.5ZM24.5 5.53125C24.5 6.06543 24.0654 6.5 23.5312 6.5C22.9971 6.5 22.5625 6.06543 22.5625 5.53125V2.46875C22.5625 1.93457 22.9971 1.5 23.5312 1.5C24.0654 1.5 24.5 1.93457 24.5 2.46875V5.53125Z" fill="black"/>
                    <path d="M17.8984 7.5C18.9838 7.5 19.8671 6.6167 19.8671 5.53125V2.46875C19.8671 1.3833 18.9838 0.5 17.8984 0.5C16.8129 0.5 15.9296 1.3833 15.9296 2.46875V5.53125C15.9296 6.6167 16.8129 7.5 17.8984 7.5ZM16.9296 2.46875C16.9296 1.93457 17.3642 1.5 17.8984 1.5C18.4326 1.5 18.8671 1.93457 18.8671 2.46875V5.53125C18.8671 6.06543 18.4326 6.5 17.8984 6.5C17.3642 6.5 16.9296 6.06543 16.9296 5.53125V2.46875Z" fill="black"/>
                    <path d="M25 9.5C24.7239 9.5 24.5 9.72388 24.5 10V16C24.5 16.2761 24.7239 16.5 25 16.5C25.2761 16.5 25.5 16.2761 25.5 16V10C25.5 9.72388 25.2761 9.5 25 9.5Z" fill="black"/>
                    <path d="M13.7344 9.5C13.4583 9.5 13.2344 9.72388 13.2344 10V16C13.2344 16.2761 13.4583 16.5 13.7344 16.5C14.0105 16.5 14.2344 16.2761 14.2344 16V10C14.2344 9.72388 14.0105 9.5 13.7344 9.5Z" fill="black"/>
                    <path d="M19.3672 9.5C18.2817 9.5 17.3984 10.3833 17.3984 11.4688V14.5312C17.3984 15.6167 18.2817 16.5 19.3672 16.5C20.4526 16.5 21.3359 15.6167 21.3359 14.5312V11.4688C21.3359 10.3833 20.4526 9.5 19.3672 9.5ZM20.3359 14.5312C20.3359 15.0654 19.9014 15.5 19.3672 15.5C18.833 15.5 18.3984 15.0654 18.3984 14.5312V11.4688C18.3984 10.9346 18.833 10.5 19.3672 10.5C19.9014 10.5 20.3359 10.9346 20.3359 11.4688V14.5312Z" fill="black"/>
                    <path d="M2.46875 9.5C1.3833 9.5 0.5 10.3833 0.5 11.4688V14.5312C0.5 15.6167 1.3833 16.5 2.46875 16.5C3.5542 16.5 4.4375 15.6167 4.4375 14.5312V11.4688C4.4375 10.3833 3.5542 9.5 2.46875 9.5ZM3.4375 14.5312C3.4375 15.0654 3.00293 15.5 2.46875 15.5C1.93457 15.5 1.5 15.0654 1.5 14.5312V11.4688C1.5 10.9346 1.93457 10.5 2.46875 10.5C3.00293 10.5 3.4375 10.9346 3.4375 11.4688V14.5312Z" fill="black"/>
                    <path d="M8.10156 9.5C7.01611 9.5 6.13281 10.3833 6.13281 11.4688V14.5312C6.13281 15.6167 7.01611 16.5 8.10156 16.5C9.18701 16.5 10.0703 15.6167 10.0703 14.5312V11.4688C10.0703 10.3833 9.18701 9.5 8.10156 9.5ZM9.07031 14.5312C9.07031 15.0654 8.63574 15.5 8.10156 15.5C7.56738 15.5 7.13281 15.0654 7.13281 14.5312V11.4688C7.13281 10.9346 7.56738 10.5 8.10156 10.5C8.63574 10.5 9.07031 10.9346 9.07031 11.4688V14.5312Z" fill="black"/>
                    <path d="M1 18.5C0.723877 18.5 0.5 18.7239 0.5 19V25C0.5 25.2761 0.723877 25.5 1 25.5C1.27612 25.5 1.5 25.2761 1.5 25V19C1.5 18.7239 1.27612 18.5 1 18.5Z" fill="black"/>
                    <path d="M12.2656 18.5C11.9895 18.5 11.7656 18.7239 11.7656 19V25C11.7656 25.2761 11.9895 25.5 12.2656 25.5C12.5417 25.5 12.7656 25.2761 12.7656 25V19C12.7656 18.7239 12.5417 18.5 12.2656 18.5Z" fill="black"/>
                    <path d="M6.63281 18.5C5.54736 18.5 4.66406 19.3833 4.66406 20.4688V23.5312C4.66406 24.6167 5.54736 25.5 6.63281 25.5C7.71826 25.5 8.60156 24.6167 8.60156 23.5312V20.4688C8.60156 19.3833 7.71826 18.5 6.63281 18.5ZM7.60156 23.5312C7.60156 24.0654 7.16699 24.5 6.63281 24.5C6.09863 24.5 5.66406 24.0654 5.66406 23.5312V20.4688C5.66406 19.9346 6.09863 19.5 6.63281 19.5C7.16699 19.5 7.60156 19.9346 7.60156 20.4688V23.5312Z" fill="black"/>
                    <path d="M23.5312 18.5C22.4458 18.5 21.5625 19.3833 21.5625 20.4688V23.5312C21.5625 24.6167 22.4458 25.5 23.5312 25.5C24.6167 25.5 25.5 24.6167 25.5 23.5312V20.4688C25.5 19.3833 24.6167 18.5 23.5312 18.5ZM24.5 23.5312C24.5 24.0654 24.0654 24.5 23.5312 24.5C22.9971 24.5 22.5625 24.0654 22.5625 23.5312V20.4688C22.5625 19.9346 22.9971 19.5 23.5312 19.5C24.0654 19.5 24.5 19.9346 24.5 20.4688V23.5312Z" fill="black"/>
                    <path d="M17.8984 18.5C16.8129 18.5 15.9296 19.3833 15.9296 20.4688V23.5312C15.9296 24.6167 16.8129 25.5 17.8984 25.5C18.9838 25.5 19.8671 24.6167 19.8671 23.5312V20.4688C19.8671 19.3833 18.9838 18.5 17.8984 18.5ZM18.8671 23.5312C18.8671 24.0654 18.4326 24.5 17.8984 24.5C17.3642 24.5 16.9296 24.0654 16.9296 23.5312V20.4688C16.9296 19.9346 17.3642 19.5 17.8984 19.5C18.4326 19.5 18.8671 19.9346 18.8671 20.4688V23.5312Z" fill="black"/>
                  </svg>
                </div>
              </motion.div>
              <h4>An AI assistant</h4>
              <br />
              <h3>concordOS</h3>
            </div>
            <div>
              <motion.div
                className={'circle clickable'}
                onClick={() => scrollToRef(concordID)}
                whileHover={{
                  scale: 0.85,
                  transition: { duration: .25 },
                }}
              >
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 26 31" fill="none">
                  <path d="M22.5823 5.78411C22.4138 5.78411 22.2454 5.71636 22.1221 5.58208C16.502 -0.538521 8.1737 1.64532 8.08947 1.66912C7.7605 1.76189 7.41321 1.56414 7.32226 1.23028C7.23132 0.897636 7.42785 0.554008 7.76111 0.463066C8.13159 0.362358 16.9139 -1.93745 23.0425 4.73614C23.2762 4.99065 23.2592 5.38616 23.0052 5.61932C22.885 5.72979 22.7336 5.78411 22.5823 5.78411Z" fill="black"/>
                  <path d="M25.2898 14.752C25.0756 14.752 24.8674 14.6422 24.7509 14.4444C21.975 9.73984 18.5021 6.82297 14.4298 5.77621C8.44532 4.23508 3.14747 7.31552 3.09498 7.34726C2.79774 7.52365 2.41443 7.42538 2.23865 7.12814C2.06226 6.83151 2.15992 6.44882 2.45655 6.27243C2.68909 6.13388 8.23658 2.90146 14.7252 4.56162C19.1375 5.69077 22.8728 8.80234 25.8275 13.8097C26.0027 14.1069 25.9038 14.4902 25.6066 14.6654C25.5071 14.724 25.3978 14.752 25.2898 14.752Z" fill="black"/>
                  <path d="M22.5829 24.4689C22.5597 24.4689 22.5365 24.4677 22.5133 24.4652C22.1703 24.4268 21.9231 24.118 21.9609 23.7749C23.0828 13.6773 15.9624 10.8544 15.8904 10.8269C15.8666 10.8184 15.8434 10.8074 15.8208 10.7958C7.70191 6.52152 1.65516 13.2921 1.59535 13.3605C1.36891 13.6211 0.974009 13.648 0.713999 13.4203C0.453989 13.1939 0.427134 12.799 0.654185 12.539C0.720713 12.4633 7.40956 4.97489 16.3775 9.67642C17.0214 9.94681 24.3834 13.2909 23.2036 23.9129C23.1682 24.2327 22.8972 24.4689 22.5829 24.4689Z" fill="black"/>
                  <path d="M17.9613 28.4961C17.8997 28.4961 17.8368 28.4869 17.7752 28.4674C17.4456 28.3648 17.2619 28.0151 17.3638 27.6855C17.3833 27.6226 19.276 21.394 16.942 17.229C15.9789 15.5102 14.4042 14.3634 12.2613 13.8202C12.2387 13.8147 8.92874 13.0664 6.2377 14.7973C4.54886 15.8831 3.43497 17.724 2.92593 20.2691C2.85818 20.6073 2.53165 20.8288 2.19046 20.7592C1.85232 20.6915 1.6326 20.3625 1.70035 20.0238C2.27957 17.1289 3.58206 15.014 5.57242 13.739C8.71817 11.723 12.4035 12.5695 12.5597 12.6062C15.0591 13.2397 16.8981 14.5898 18.0352 16.6223C20.6304 21.2603 18.6431 27.7819 18.5576 28.0572C18.474 28.3245 18.2274 28.4961 17.9613 28.4961Z" fill="black"/>
                  <path d="M13.0083 30.0144C12.91 30.0144 12.8099 29.9912 12.7172 29.9424C12.412 29.7813 12.2948 29.4028 12.4559 29.0977C13.7438 26.6581 14.0563 24.3766 14.0917 22.8922C14.1307 21.262 13.4514 19.7123 12.2759 18.7467C11.2541 17.9087 10.2556 17.7128 9.22473 18.1473C8.09253 18.6252 7.29907 19.7941 7.20203 21.1259C6.99756 23.9512 6.01367 25.8958 5.97217 25.9775C5.81531 26.2839 5.44116 26.4042 5.13171 26.2479C4.82471 26.0911 4.70325 25.7145 4.8595 25.4075C4.86865 25.3898 5.77014 23.5935 5.95569 21.0355C6.08569 19.2399 7.17822 17.6542 8.73889 16.9962C9.75391 16.5665 11.3311 16.3547 13.0693 17.7805C14.5397 18.9884 15.3893 20.9104 15.3417 22.9215C15.3026 24.5402 14.9626 27.0267 13.5619 29.6812C13.4496 29.8936 13.2329 30.0144 13.0083 30.0144Z" fill="black"/>
                  <path d="M8.71754 29.1564C8.5442 29.1564 8.37147 29.0844 8.24818 28.944C8.0199 28.6846 8.04554 28.2897 8.30494 28.062C9.32056 27.1691 9.91016 24.8027 9.92298 21.5691C9.9242 21.2249 10.2037 20.9465 10.548 20.9465C10.5486 20.9465 10.5498 20.9465 10.5504 20.9465C10.8959 20.9478 11.1742 21.2285 11.173 21.574C11.1644 23.6846 10.8886 27.4559 9.13013 29.0007C9.01173 29.1051 8.86402 29.1564 8.71754 29.1564Z" fill="black"/>
                </svg>
                </div>
              </motion.div>
              <h4>A consumer ID</h4>
              <br />
              <h3>concordID</h3>
            </div>
            <div>
              <motion.div
                className={'circle clickable'}
                onClick={() => scrollToRef(concordAPP)}
                whileHover={{
                  scale: 0.85,
                  transition: { duration: .25 },
                }}
              >
                <div><svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 26 28" fill="none">
                  <path d="M0.510204 24.439C0.512381 24.5446 0.541714 24.6175 0.57861 24.688C0.645011 24.8156 0.762345 24.9144 0.87584 24.9586C0.883517 24.9616 0.889418 24.9672 0.897267 24.9699L8.99255 27.7195C9.04823 27.7384 9.10993 27.7469 9.16911 27.7481C9.17232 27.7482 9.17501 27.75 9.17828 27.75C9.17862 27.75 9.17897 27.7498 9.17932 27.7498C9.17971 27.7498 9.18011 27.75 9.18051 27.75C9.24319 27.75 9.30529 27.7396 9.36459 27.7195C11.0201 27.1563 3.36757 29.7595 25.5551 22.2119C25.7879 22.133 25.944 21.9148 25.944 21.6695V11.2358C25.944 11.1038 25.9004 10.9834 25.8107 10.8743C25.7441 10.7935 25.6018 10.7068 25.5366 10.6872L17.8448 8.3667V3.58083C17.8448 3.4431 17.7956 3.32382 17.7135 3.22259C17.6483 3.14266 17.5129 3.05764 17.4565 3.03839L9.35062 0.280423C9.2303 0.239288 9.10053 0.240434 8.98136 0.280423L0.888324 3.03839C0.704589 3.10096 0.5 3.30978 0.5 3.58112C0.500802 5.4773 0.505958 22.5438 0.508422 24.4247C0.508422 24.4256 0.507849 24.4264 0.507849 24.4274C0.507855 24.4315 0.510146 24.4349 0.510204 24.439ZM1.65014 14.8141L8.60536 17.1766V26.3777L1.65426 24.0168L1.65014 14.8141ZM9.7512 17.1739L16.699 14.814V24.0184L9.7512 26.3781V17.1739V17.1739ZM16.699 13.5855L9.74507 15.9474V6.74231L16.699 4.3804V13.5855ZM8.59923 15.9419L1.64979 13.5826L1.64602 4.38034L8.59923 6.7411V15.9419ZM17.8487 23.6194V14.3957L24.7981 12.0353V21.2591L17.8487 23.6194ZM23.4947 11.2681L17.8448 13.1871V9.56381L23.4947 11.2681ZM9.16596 1.42792L15.4922 3.58037L9.1741 5.72624L2.85195 3.57991L9.16596 1.42792Z" fill="black"/>
                </svg></div>
              </motion.div>
              <h4>A modular kitchen appliance system</h4>
              <br />
              <h3>concordAPP</h3>
            </div>
          </div>
        </div>
        <hr />
      </motion.section>


      <motion.section className={'content col-two'} variants={animateInDown} ref={concordOS}>
        <div className={'content card--shortDesc'}>
          <h4>concordOS</h4>
          <p>ConcordOS analyses, understands and provides feedback on users’ consumption patterns in order to make purchases for them that better suit their needs and requirements. These personalized requirements are identified and continuously updated through a combination of direct user input and behind the scenes behavioural analysis.</p>
        </div>
        <Multivariate />
      </motion.section>
      <motion.section className={'content col-two'} variants={animateInDown} ref={concordID}>
        <div className={'card--shortDesc'}>
          <h4>concordID</h4>
          <p>concordID represents the users’ profile and preferences that evolves over time and a rating for products and goods themselves.</p>
        </div>
        <Radial />

      </motion.section>

      <motion.section className={'content col-one no-bottom'} variants={animateInDown} ref={concordAPP}>
        <div className={'card--shortDesc'}>
          <h4>concordAPP</h4>
          <p>Concord is physically represented as a series of interconnected modular kitchen appliances, concordAPPs. These modules fulfil every necessary function in the kitchen such as storage, preparation, cultivation and disposal. The combination of modules a user requires is determined by their concordID, the physical constraints and allowances of the space they occupy. Modules connect and work together to maximize efficiency (e.g. heat & energy transfer) and minimize food waste.</p>
        </div>
        <div />
      </motion.section>



      {/*<motion.section variants={animateInDown}>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p>*/}
      {/*      As a consumer, you have a fingerprint of these values, they change and adjust as you do.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className={'chart-row'}>*/}
      {/*    <img src={chart} alt={'chart showing data'} />*/}
      {/*  </div>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p>*/}
      {/*      Concord allows a safe space for your purchasing behaviour data, where you can learn about your purchasing norms while staying the owner of your data.and do what you want with it.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</motion.section>*/}

      <motion.section variants={animateInDown}>
        <Modular />
      </motion.section>

      <motion.section variants={animateInDown}>
        <Transaction />
      </motion.section>

      {/*<motion.section variants={animateInDown}>*/}
      {/*  <Xr />*/}
      {/*</motion.section>*/}

      {/*<motion.section variants={animateInDown}>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p>*/}
      {/*      The physical infrastructure of Concord is best defined as  realised as a transaction membrane. A transaction membrane is a space where a physical exchange takes place, like a porch, garage, or mailbox—or in this case, a kitchen-based window—where products can come and go.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p className={'quote'}>*/}
      {/*      Concord is a modular set of <em>these appliance-based windows</em> where products and produce can transition from supply chain to our homes.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p>*/}
      {/*      Whether delivered by ground or flying drones, human messager, or even a pipe-based subterranean infrastructure, Concord is built to connect with a variety of supply chain logistics.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</motion.section>*/}

      <motion.section variants={animateInDown}>
        <motion.div
          animate={{
            x: -(SLIDER_WIDTH * modules.length + SLIDER_PADDING * modules.length)
          }}
          transition={{
            duration: 20,
            ease: "linear",
            loop: Infinity
          }}
          className={'slider'}
          ref={slider}
          style={{width: adjustedSliderWidth}}
        >
          { generateSlider() }
        </motion.div>

      </motion.section>

      <motion.section variants={animateInDown}>
        <UseCase />
      </motion.section>

      {/*<motion.section variants={animateInDown}>*/}
      {/*  <div className={'content'}>*/}
      {/*    <p>*/}
      {/*      Its modularity and versatility allow it to be embedded in a variety of locations suiting the individual needs of a household.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className={'content'}>*/}
      {/*    <h4>*/}
      {/*      Concord is an appliance, a personal intelligent assistant, a tool for those wanting to break the mold of traditional systems. It's a tool for those who want to be empowered by their purchases. One where their data isn't sold to optimize pushing more products but where their data, only if they chose, can be allowed to help others discover different types of cost and carbon saving.*/}
      {/*    </h4>*/}
      {/*  </div>*/}
      {/*  <div className={'content--center'}>*/}
      {/*    <Button*/}
      {/*      onPress={() => (console.log('button press'))}*/}
      {/*      style={STYLES.LIGHT}*/}
      {/*    >*/}
      {/*      Build My ID*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</motion.section>*/}




      <motion.div className={'content col-two'} variants={ animateInDown } ref={confettiContainer}>
        <div className={'shadow-object content card--confetti'} >
          <div className={'card--confetti--content'}>
          <p>
            A project designed by&nbsp;
            <a href={'https://www.weird.fyi'} target={'_blank'} rel="noopener noreferrer">W3}RD Collective</a>
            .&nbsp;<br /><br />
            Concept of Frictionless Kitchen & Transaction Membrane by&nbsp;
            <a href={'https://www.futurity.studio'} target={'_blank'} rel="noopener noreferrer">Futurity Studio</a>
            .&nbsp;<br /><br />
            This is a speculative design project about the future of consumption.
          </p>
          </div>
          <canvas ref={confettiCanvas} />
        </div>

        <div className={'shadow-object content circle image'}>
          <img alt={'blurred-cloud modules'} src={require('../../../images/blurred-cloudst.jpg')} />
        </div>
      </motion.div>

    </motion.div>
  );
}

export default withRouter(Narrative);