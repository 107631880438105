import React, { useState  } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import "./Multivariate.scss"

const Multivariate = () => {
  const [value, setValue] = useState(null);

  const data = [
    {
      title: 'Ecological',
      desc: 'Carbon footprint and resources needed for production'
    },
    {
      title: 'Social',
      desc: 'Working conditions of the humans in the supply/production chain'
    },
    {
      title: 'Health',
      desc: 'Nutritional values, carcinogen levels, dietary restrictions'
    },
    {
      title: 'Convenience',
      desc: 'Time and effort available and invested'
    },
    {
      title: 'Financial',
      desc: 'Significance of economic value'
    },
  ]

  const generateContentCards = (data) => {
    return(
      <motion.div
        key={data.title}
        initial={{opacity: 0}}
        animate={{
          opacity: 1,
          transition: {
            duration: .25,
            easings: "linear"
          }
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: .25,
            easings: "linear"
          }
        }}>
        <h3>{data.title}</h3>
        <p>{data.desc}</p>
      </motion.div>
    );
  }

  const genericCard = () => {
    return(
      <motion.div
        key={0}
        initial={{opacity: 0}}
        animate={{
          opacity: 1,
          transition: {
            duration: .25,
            easings: "linear"
          }
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: .25,
            easings: "linear"
          }
        }}>
        <p>
          concordOS utilises a value system which is based on 5 metrics whereby the user profile can be defined, and the goods can be measured
        </p>
      </motion.div>
    );
  }

  const contentCards = [
    generateContentCards(data[0]),
    generateContentCards(data[1]),
    generateContentCards(data[2]),
    generateContentCards(data[3]),
    generateContentCards(data[4])
  ];

  return(
    <div
      className={'Multivariate'}
    >

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 599 572" onMouseLeave={() => setValue(null)}>
        <circle cx="300" cy="319" r="249.5" stroke="#979797"/>
        <rect width="88" height="88" x="256" y="25" fill="#F7F7F7" rx="44"/>
        <g filter="url(#filter0_d)">
          <rect width="88" height="88" x="256" y="25" fill="#F7F7F7" rx="44"/>
        </g>
        <motion.g
          className={'interactable'}
          whileHover={{
            scale: 0.85,
            transition: { duration: .25 },
          }}
          onMouseEnter={() => setValue(0)}
          filter="url(#filter1_d)">
          <rect
            width="88" height="88" x="256" y="25" fill="#F7F7F7" rx="44"/>
        </motion.g>
        <circle cx="300" cy="69" r="22" fill="url(#paint0_linear)"/>
        <rect width="88" height="88" x="486" y="175" fill="#F7F7F7" rx="44"/>
        <g filter="url(#filter2_d)">
          <rect width="88" height="88" x="486" y="175" fill="#F7F7F7" rx="44"/>
        </g>
        <motion.g
          className={'interactable'}
          whileHover={{
            scale: 0.85,
            transition: { duration: .25 },
          }}
          onMouseEnter={() => setValue(3)}
          filter="url(#filter3_d)">
          <rect width="88" height="88" x="486" y="175" fill="#F7F7F7" rx="44"/>
        </motion.g>
        <path fill="url(#paint1_linear)" fillRule="evenodd" d="M530 191l22 44h-44l22-44z" clipRule="evenodd"/>
        <rect
          width="88" height="88" x="416" y="459" fill="#F7F7F7" rx="44"/>
        <g filter="url(#filter4_d)">
          <rect width="88" height="88" x="416" y="459" fill="#F7F7F7" rx="44"/>
        </g>
        <motion.g
          className={'interactable'}
          whileHover={{
            scale: 0.85,
            transition: { duration: .25 },
          }}
          onMouseEnter={() => setValue(4)}
          filter="url(#filter5_d)">
          <rect width="88" height="88" x="416" y="459" fill="#F7F7F7" rx="44"/>
        </motion.g>
        <path fill="url(#paint2_linear)" fillRule="evenodd" d="M460 481l20.92 15.2-7.99 24.6h-25.86l-8-24.6L460 481z" clipRule="evenodd"/>
        <rect
          width="88" height="88" x="81" y="459" fill="#F7F7F7" rx="44"/>
        <g filter="url(#filter6_d)">
          <rect width="88" height="88" x="81" y="459" fill="#F7F7F7" rx="44"/>
        </g>
        <motion.g
          className={'interactable'}
          whileHover={{
            scale: 0.85,
            transition: { duration: .25 },
          }}
          onMouseEnter={() => setValue(2)}
          filter="url(#filter7_d)">
          <rect width="88" height="88" x="81" y="459" fill="#F7F7F7" rx="44"/>
        </motion.g>
        <path fill="url(#paint3_linear)" d="M113 525h44v24h-44z" transform="rotate(-90 113 525)"/>
        <rect
          width="88" height="88" x="25" y="175" fill="#F7F7F7" rx="44"/>
        <g filter="url(#filter8_d)">
          <rect width="88" height="88" x="25" y="175" fill="#F7F7F7" rx="44"/>
        </g>
        <motion.g
          className={'interactable'}
          whileHover={{
            scale: 0.85,
            transition: { duration: .25 },
          }}
          onMouseEnter={() => setValue(1)}
          filter="url(#filter9_d)">
          <rect width="88" height="88" x="25" y="175" fill="#F7F7F7" rx="44"/>
        </motion.g>
        <path fill="url(#paint4_linear)" d="M91 197h44v44H91z" transform="rotate(90 91 197)"/>
        <defs>
          <filter id="filter0_d" width="120" height="120" x="231" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="-9" dy="-9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter1_d" width="120" height="120" x="249" y="18" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="9" dy="9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter2_d" width="120" height="120" x="461" y="150" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="-9" dy="-9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter3_d" width="120" height="120" x="479" y="168" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="9" dy="9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter4_d" width="120" height="120" x="391" y="434" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="-9" dy="-9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter5_d" width="120" height="120" x="409" y="452" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="9" dy="9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter6_d" width="120" height="120" x="56" y="434" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="-9" dy="-9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter7_d" width="120" height="120" x="74" y="452" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="9" dy="9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter8_d" width="120" height="120" x="0" y="150" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="-9" dy="-9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <filter id="filter9_d" width="120" height="120" x="18" y="168" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="9" dy="9"/>
            <feGaussianBlur stdDeviation="8"/>
            <feColorMatrix values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear" x1="278" x2="278" y1="47" y2="91" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAC5CF"/>
            <stop offset="1" stopColor="#84ACD8"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="508" x2="508" y1="191" y2="235" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B3DFDB"/>
            <stop offset="1" stopColor="#92C0D0"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="438" x2="438" y1="481" y2="525" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D8EDE3"/>
            <stop offset="1" stopColor="#AFDFDD"/>
          </linearGradient>
          <linearGradient id="paint3_linear" x1="113" x2="113" y1="525" y2="549" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E5909B"/>
            <stop offset="1" stopColor="#F4D5C8"/>
          </linearGradient>
          <linearGradient id="paint4_linear" x1="91" x2="91" y1="197" y2="241" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B4D4EC"/>
            <stop offset="1" stopColor="#F1E2D2"/>
          </linearGradient>
        </defs>
      </svg>

      <div className={'description-container'}>
        <div className={'description shadow-object'}>
          <AnimatePresence exitBeforeEnter>
            { (value === 0) &&
            contentCards[0]
            }
            { (value === 1) &&
            contentCards[1]
            }
            { (value === 2) &&
            contentCards[2]
            }
            { (value === 3) &&
            contentCards[3]
            }
            { (value === 4) &&
            contentCards[4]
            }
            { (value == null) &&
              genericCard()
            }
          </AnimatePresence>
        </div>
      </div>

    </div>
  );
};

export default Multivariate;