import React, { useState  } from 'react';
import "./Radial.scss"
import Plot from 'react-plotly.js';

const Radial = () => {
  let data = [
    {
      "type": "sunburst",
      "labels": ["True Cost", "Ecological", "Health", "Convenience", "Financial", "Social",
        // Ecological
        "Water Consumption", "Land Impact",
        "Eutrophication","Desertification",
        "Carbon Footprint",

        // Health
        "Nutritional Density",
        "Vitamin",
        "B12", "Essential Aminos", "Omega 3",
        "Minerals",
        "Fats",
        "Proteins",
        "Carbohydrates",
        "Restrictions",
        "Lactose Intolerant", "Vegetarian", "Vegan", "Pescatarian", "Peanut Allergy", "Diabetic", "Gluten Free", "Kosher", "Halal", "Non-GMO", "Plant Based", "Jain", "Raw", "Paleo", "Keto",

        // C
        "Availability",
        "Store Stock","Store Hours","Store Proximity",
        "Ease of Use",
        "Ease of Transport",
        "Shelf Life",

        // F
        "Price",
        "Price per Unit",
        "Price per Kilo",
        "Delivery Price",
        "Packaging Price",
        "Return Price",

        //S
        "Culture",
        "LGBTQAI+ Owned",
        "Locally Made",
        "Woman Owned",
        "Nationally Produced",
        "Festivals",
        "History",
        "Context",
        "Cuisine",
        "Festivals",
      ],
      "parents": ["", "True Cost", "True Cost", "True Cost", "True Cost", "True Cost",

        // E
        "Ecological","Ecological",
        "Land Impact","Land Impact",
        "Ecological",

        // H
        "Health", "Health",
        "Vitamin", "Vitamin", "Vitamin",
        "Health", "Health", "Health", "Health", "Health",
        "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions","Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions", "Restrictions",

        //C
        "Convenience",
        "Availability", "Availability", "Availability",
        "Convenience", "Convenience", "Convenience",

        //F
        "Financial",
        "Price", "Price",
        "Financial", "Financial", "Financial",

        //S
        "Social",
        "Culture","Culture","Culture","Culture",
        "Social","Social","Social","Social","Social",
      ],
      // "values":  [100, 20, 20, 20, 20, 20, ],
      "leaf": {"opacity": 0.8},
      "marker": {"line": {"width": 2}},
      "branchvalues": 'total',
      "hoverinfo": "none"
    }];

  const layout = {
    "margin": {"l": 0, "r": 0, "b": 0, "t": 0},
    "sunburstcolorway": ["#84ACD8","#E5909B","#B3DFDB", "#D8EDE3","#F1E2D2"],
    "extendsunburstcolorway": false
  };

  return(
    <div className={'Radial'}>
      <Plot
        data={data}
        layout={layout}
      />
      <img src={require('../../images/ConcordOS-bg.svg')} alt={'background'}/>
    </div>
  )
};

export default Radial;