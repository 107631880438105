export const { NODE_ENV } = process.env;

const isDev = NODE_ENV === 'development';
const isStaging = NODE_ENV === 'staging';

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop, {top: 0, left: 100, behavior: 'smooth'})


export const logger = {
  trace: (...x) => {
    if (isDev) {
      console.log('[TRACE]', new Date().getTime(), ...x);
      console.trace();
    }
  },
  silly: (...x) => {
    if (isDev) {
      console.log('[SILLY]', new Date().getTime(), ...x);
    }
  },
  debug: (...x) => {
    if (isDev || isStaging) {
      console.log('[DEBUG]', new Date().getTime(), ...x);
    }
  },
  info: (...x) => {
    if (isDev || isStaging) {
      console.log('[INFO]', new Date().getTime(), ...x);
    }
  },
  error: (...x) => {
    console.log('[ERROR]', new Date().getTime(), ...x);
  },
  fatal: (...x) => {
    console.log('[FATAL]', new Date().getTime(), ...x);
  }
};