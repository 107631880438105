import React from 'react';
import "./Onboarding.scss";
import { ROUTES } from '../../constants';
import { Landing, Narrative } from "./views";
import { AnimatePresence } from 'framer-motion'; //used with other pages...

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

const Onboarding = () => {
  return (
    <div className={'Onboarding'}>
      <Switch>
        <Route exact path={ROUTES.ROOT}>
          <Redirect to={ROUTES.STORY} />
        </Route>
        <Route exact path={ROUTES.LANDING}>
          <Landing />
        </Route>
        <Route exact path={ROUTES.STORY}>
          <AnimatePresence exitBeforeEnter={true} >
            <Narrative />
          </AnimatePresence>
        </Route>
      </Switch>
    </div>
  );
}

export default Onboarding
