import React, { useState, useEffect, useCallback } from 'react';
import Lottie from "lottie-react";
import { transactionMembrane, transactionMembraneMobile  } from "../../animations";
import './Transaction.scss';

const Transaction = () => {
  const [ windowWitdh, setWindowWidth] = useState(null);

  const updateWidth = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, [window]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    }
  }, [updateWidth])


  return(
    <div className={'Transaction content'} >
      { (windowWitdh > 850) ?
        <Lottie animationData={transactionMembrane}/>
        :
        <Lottie animationData={transactionMembraneMobile}/>
      }
      <div className={'desc'}>
        <div>
          <h4>Transaction Membrane</h4>
          <p>The modules that form concordAPPs once installed and fitted into a kitchen forms a transaction membrane. The membrane is the interface for exchanging goods autonomously between the modules and the delivery infrastructure which include air, ground or subterranean transport. Produced waste will be automatically discarded the same way.</p>
        </div>
        <img src={require('../../images/transaction-membrane-animation.png')} alt={'moduless'}/>
      </div>
    </div>
  )
};

export default Transaction;