import React from 'react';
import './Button.scss';

export const STYLES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
}

const Button = ({onPress, children, style, disabled, alt}) => {

  let classname = '';

  Object.values(STYLES).forEach((v) => {
    if (v === style) {
      classname = `${style.toLowerCase()}`;
    }
  });

  return(
    <button
      onClick={onPress}
      className={`Button ${classname} ${ (alt) ? 'alt' : ''}`}
      disabled={disabled}
    >
      { children }
    </button>
  )
}

export default Button;