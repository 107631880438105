import React, { useState  } from 'react';
import {AnimatePresence, motion} from "framer-motion";
import './UseCase.scss';
import {Button, STYLES} from "../index";

const UseCase = () => {
  const [current, setCurrent] = useState(0);
  const [description, setDescription] = useState(true);
  const caseData = [
    {
      name: 'Jordan',
      subText: '22 years | Student | Omnivore',
      persona: 'Jordan has a low budget for grocery shopping. He likes grilled cheese sandwiches, snacks, drinks alcohol and smokes. He has stated that he has problems with digestion, and wants to start making healthier eating habits.',
      solution: 'Concord balances Jordan’s nutritional needs against his budget in order to assist Jordan with his healthier diet. It gathers his address, located within a shared student flat and synchronizes with the other tenants’ appliances, in order to make an appropriate list of meals which are both cheap and efficient to cook within a flatshare.',
      signature: '',
      modules: 'jordan-final.png'
    },
    {
      name: 'Ari',
      subText: '35 years | Professional | Vegan',
      persona: 'Ari has a medium budget for grocery shopping. He likes to cook but has limited time, he is active and plays sport 3 times a week.',
      solution: 'Concord monitors the nutritional value of his meals in order to optimise and prioritise high levels of protein in order to align with his levels of physical activity. Concord does this by purchasing meal kits with healthy plant-based recipes that can be made swiftly at home.',
      signature: '',
      modules: 'ari-final.png'
    },
    {
      name: 'Smith Family',
      subText: '56, 49, 15, 12 years | Omnivores',
      persona: 'The Smith family has a high budget per month. The father has a high profile professional job that is stressful and demanding. The mother works on a more flexible schedule and practices yoga and pilates. The oldest child is an adolescent that plays volleyball for the school team and the youngest plays video games and is not very active despite needing high food intake. The family owns a medium-sized dog.',
      solution: 'Due to the father\'s job and time constraints, Concord delivers personalised meals to his office. Concord always keeps fresh fruit and healthy snacks for the children, taking into account their individual developmental needs. As the mother is premenopausal, she is also supplied supplements to help ease the transition.  Concord also accounts for fresh raw food for the family pet, keeping him healthy and active.',
      signature: '',
      modules: 'smith-final.png'
    },
  ]

  return(
    <>
      <div className={'UseCase content'}>
        <div>
          <h3>Use Cases</h3>
          <p>Examples of different Concord units<br />and their respective use cases</p>
        </div>
      </div>
      <div className={'UseCase content col-two'} >
        <motion.div className={'card--useCase shadow-object'}>
          <div>
            <div className={'UseCase--header'}>
              <div className={'concordId'}>
                <div>
                  <div radial={current}>
                    <div />
                  </div>
                </div>
              </div>
              <div>
                <h3>{caseData[current].name}</h3>
                <p>{caseData[current].subText}</p>
              </div>
            </div>
            <hr />
            <Button
              style={STYLES.DARK}
              children={<h4>Persona</h4>}
              onPress={() => setDescription(true)}
              alt={(description === false)}
            />
            <Button
              style={STYLES.DARK}
              children={<h4>Concord</h4>}
              onPress={() => setDescription(false)}
              alt={(description === true)}
            />
          </div>
          {description ?
            <p>{caseData[current].persona}</p>
            :
            <p>{caseData[current].solution}</p>
          }
          <motion.div
            className={'button--prev shadow-object'}
            onClick={()=> setCurrent( (current - 1 + 3) % caseData.length)}
            whileHover={{
              scale: 0.85,
              transition: { duration: .25 },
            }}
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                <path opacity="0.5" d="M10.8379 15.1412L4.3425 8.64575L10.8379 2.15034L8.83334 0.145752L0.333336 8.64575L8.83334 17.1458L10.8379 15.1412Z" fill="#111111"/>
              </svg>
            </div>
          </motion.div>
          <motion.div
            className={'button--next shadow-object'}
            onClick={()=> setCurrent( Math.abs(current + 1) % caseData.length)}
            whileHover={{
              scale: 0.85,
              transition: { duration: .25 },
            }}
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                <path opacity="0.5" d="M0.162086 2.85883L6.6575 9.35425L0.162085 15.8497L2.16667 17.8542L10.6667 9.35425L2.16667 0.854247L0.162086 2.85883Z" fill="#111111"/>
              </svg>
            </div>
          </motion.div>
        </motion.div>
        <motion.div className={'card--useCaseImage shadow-object'}>
          <img
            alt={'personalized modules'}
            src={require(`../../images/${caseData[current].modules}`)}
          />
        </motion.div>
      </div>
    </>
  )

};

export default UseCase;