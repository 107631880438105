import React, { Component } from 'react';
import './Landing.scss';
import { Button, STYLES } from "../../../components";
import { withRouter } from 'react-router';
import {ROUTES} from "../../../constants";
import useMobileDetect from 'use-mobile-detect-hook';


const Landing = ({history}) => {
  const detectMobile = useMobileDetect();

  return(
    <div className={'Landing'}>
      <div />
      <div>
        <em>2050</em>
        <h1>Concord</h1>
        <h3>Your Future Kitchen!</h3>
        {
          detectMobile.isDesktop() ?
          <Button
            onPress={() => (history.push(ROUTES.STORY))}
            style={STYLES.LIGHT}
          >
            Start Now
          </Button>
            :
            <p>This experience is best on Desktop</p>
        }
      </div>
      <div />
    </div>
  );
}

export default withRouter(Landing);