import React, {Component} from 'react';
import './theme/theme.scss';
import Onboarding from "./screens/Onboarding/Onboarding";
import Normalize from "react-normalize";
import {BrowserRouter as Router} from "react-router-dom";


class App extends Component{
  componentDidMount(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  render() {
    return (
      <main className="App">
        <Normalize/>

        <Router>
          <Onboarding/>
        </Router>

      </main>
    );
  };
}

export default App;
