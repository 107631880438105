import React, { useState  } from 'react';
import {AnimatePresence, motion} from "framer-motion";
import './Modular.scss';

export const moduleData = [
  {
    name: 'Aura',
    desc: 'Aura is a self-sustaining a growing system that doesn\t need soil or an aggregate medium, just mist and air. Aura connects with Instrumentum in order to freshly prepare food. The water used for irrigation is repurposed from rainwater and redistributed among the other modules for cooling.',
    image: 'aeroponics',
    placement: 'tr'
  },
  {
    name: 'Calidi',
    desc: 'Produce that arrives directly for consumption is kept warm in Calidi as well as for cooking food that has been prepared in Instrumentum. Calidi\'s ranges from 0℃ to +500℃ with the option of internal subdivisions to store and prepare different produce at specific temperatures. Calidi is designed for maximum efficiency, any excess heat is transferred to the buildings central heating system.',
    image: 'hot',
    placement: 'tm'
  },
  {
    name: 'Frigus',
    desc: 'Frigus temperatures range from of -20℃ to +5℃. Frigus can be subdivided internally so food can be stored at a range of temperatures depending on user preferences and module contents. Through automatic preparation, Frigus identifies produce that is close to being spoiled tp prioritize consumption or donation.',
    image: 'cold',
    placement: 'tl'
  },
  {
    name: 'Arca',
    desc: 'Storage of goods and food, maintained in a cool and dry environment. The pantry directly connects to Frigus, Calidi and Instrumentum modules for food preparation. The pantry can also be used for the storage of other deliveries that the household may receive.',
    image: 'pantry',
    placement: 'bl'
  },
  {
    name: 'Instrumentum',
    desc: 'Instrumentum can be thought of as the heart of the concordAPP system as it relies on communicating with all of the other modules in the preparation of food and meals for users. Meals can be prepared automatically as per the concordID but user input is also possible through adjusting settings on the controls on the modules itself.',
    image: 'appliance',
    placement: 'c'
  },
  {
    name: 'Gyrus',
    desc: 'This module allows for the minimized waste of the concordAPP system through any left of produce from food preparation and meals can be put into Gyrus and used for nutrients needed in Aura and Fermentum or can be redistributed in the concord network to another household.',
    image: 'compost',
    placement: 'cr'
  },
  {
    name: 'Purga',
    desc: 'For all of the produce and waste that can’t be absorbed by Gyrus, Purga disposes, sorts, and recycles the waste from the household to the proper facility. Purga also tracks which product waist can be directly sent back to the producer.',
    image: 'trash',
    placement: 'crr'
  },
  {
    name: 'Fermentum',
    desc: 'This module, like Aura is used to produce material for user consumption through the process of fermentation. The module can be subdivided to allow for the different environments needed for specific types of fermentation such as Kombucha, Kefir and Sauerkraut. All of the modules are dry, but can be fine-tuned in terms of light, air and temperature levels (24-28℃) for the optimal environments.',
    image: 'fermentation',
    placement: 'br'
  }
]

const Modular = () => {


  const [module, setModule] = useState(1);

  const generateModules = () => {
    return moduleData.map((md,i) =>
      <div key={i} data-grid-placment={md.placement}>
        <motion.img
          src={ require( '../../images/' + md.image + '.png')}
          alt={'appliance module'}
          whileHover={{ scale: 0.9 }}
          onMouseEnter={() => {setModule(i)}}
        />
      </div>
    );
  }

  return(
    <div className={'Modular content no-top'} >

      <div className={'col'}>
        <AnimatePresence>
          <motion.div
            className={'desc'}
            initial={{ x: -50, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition:{
                duration: .25,
                easings: "linear"
              }
            }}
            exit={{
              x: -50,
              opacity: 0,
              transition:{
                duration: .25,
                easings: "linear"
              }
            }}
          >
            <div>
            <h3>{moduleData[module].name}</h3>
            <p>{moduleData[module].desc}</p>
            </div>
            <img
              src={ require( '../../images/' + moduleData[module].image + '-iso.png')}
              alt={'appliance module'}
            />
          </motion.div>
        </AnimatePresence>
      </div>

      <div className={'col'}>
        <div className={'modules'}>
          { generateModules() }
        </div>
      </div>
    </div>
  )
};

export default Modular;

