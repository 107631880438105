import React from 'react';
import './Xr.scss';

import * as Model from '@google/model-viewer';
import '@google/model-viewer';

const XrObject = require('../../3D/websiteModule.glb')
const XrObjectIOs = require('../../3D/websiteModule-IOS.usdz')

const Xr = () => {
  console.log(XrObject);
  return(
    <div className={'Xr content'} >
      <model-viewer
        src='../3D/websiteModule.glb'

        camera-controls autoplay ar shadow-intensity="1"

        ar ar-modes="webxr scene-viewer quick-look" ar-scale="auto" camera-controls

        alt='Concord Modules'

        // skybox-image="../shared-assets/environments/aircraft_workshop_01_1k.hdr"

        ios-src="../3D/websiteModule-IOS.usdz.glb"
      >
      </model-viewer>
    </div>
  )
}

export default Xr;